@use "../../../../styles/mixins/setFonts" as *;
@use "../../../../styles/variables/colors/colors";

.reviews {
  background-color: colors.$sectionPrimaryBg;
  display: flex;
  justify-content: center;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 15px;
    max-width: 1240px;
  }

  &__info {
    max-width: 370px;
    padding: 0 15px;
  }

  &__header {
    @include setFonts('Roboto', 1.75rem, 2rem, 700);
    color: colors.$blackTextColor;
    margin-bottom: 20px;
  }

  &__subheader {
    @include setFonts('Roboto', 1rem, 1.5rem, 400);
    color: colors.$blackTextColor;
  }

  &__slider-wrapper {
    max-width: 800px;

    &--one {
      max-width: 380px;
    }
  }
}

.slide {
  &__container {
    max-width: 370px;
    width: 100%;
    padding: 31px 32px 34px;
    background: #FFFFFF;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    min-height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    @include setFonts("Roboto", 1rem, 1.5rem, 400);
    color: #6D7175;
  }

  &__img-wrapper {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    background-color: #071038;
    border-radius: 50%;
    padding: 11px;
    margin-right: 15px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &__app-name {
    @include setFonts("Roboto", 1.25rem, 1.75rem, 400);
    color: colors.$blackTextColor;
  }
}

.stars-wrapper {
  display: flex;
  align-items: center;
}


.slide__text.three-dots {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .reviews {
    &__container {
      flex-direction: column;
    }

    &__info {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: 820px) {
  .reviews {
    &__slider-wrapper {
      max-width: 380px;
    }

    &__info {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 540px) {
  .slide {
    &__container {
      flex-direction: column-reverse;
    }

    &__footer {
      border-bottom: 1px solid #EAEAEA;
      padding-bottom: 16px;

      &-review {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .reviews {
    &__slider-wrapper {
      max-width: 300px;
    }
  }
  .slide__container {
    max-width: 290px;
  }
}